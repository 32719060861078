body {
  max-width: 100vw;
  overflow-x: hidden;
}


.bg {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;

  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 31% 0%, rgba(11, 62, 132, 1) 0%, rgba(1, 13, 55, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 31% 0%, rgba(11, 62, 132, 1) 0%, rgba(1, 13, 55, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 31% 0%, rgba(11, 62, 132, 1) 0%, rgba(1, 13, 55, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 31% 0%, rgba(11, 62, 132, 1) 0%, rgba(1, 13, 55, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 31% 0%, rgb(11, 62, 132) 0%, rgba(1, 13, 55, 1) 100%);

  background-size: cover;
  background-position: center;
  z-index: -100;
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 142px;
  font-size: 18px;
  line-height: 30px;
  color: var(--color-additonal);
  @media screen and (min-width: 1600px) {
    width: 70%;
    padding-bottom: 245px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 1320px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 100%;
    padding: 0 4rem 6rem 4rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
    padding: 0 15px 5rem 15px;
  }
  p {
    padding-bottom: 10px;
  }
}

.title-wrapper {
  margin: 0 auto;
  @media screen and (min-width: 1600px) {
    padding-top: 7%;
    padding-bottom: 5rem;
    max-width: 70%;
  }
  @media screen and (max-width: 1600px) {
    width: 1320px;
    padding: 6rem 0 3rem 0;
  }
  @media screen and (max-width: 1400px) {
    width: 100%;
    padding: 4rem 0 3rem 4rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 0 2rem 4rem;
  }
  @media screen and (max-width: 576px) {
    padding: 30px 15px;
    font-size: 1.5rem;
  }

  h1 {
    font-size: 2.2rem;
    font-weight: 800;
    width: max-content;
    padding: 0;

    @media screen and (max-width: 1200px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
}

a {
  color: unset;
  text-decoration: none;
}

.app-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.gradient-bg {

  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 31% 0%, rgba(0, 47, 129, 1) 9%, rgba(0, 32, 65, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 31% 0%, rgba(0, 47, 129, 1) 9%, rgba(0, 32, 65, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 31% 0%, rgba(0, 47, 129, 1) 9%, rgba(0, 32, 65, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 31% 0%, rgba(0, 47, 129, 1) 9%, rgba(0, 32, 65, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 31% 0%, rgba(0, 47, 129, 1) 9%, rgba(0, 32, 65, 1) 100%);

}

.gradient-text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
  animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.9s both;
  animation: slide-in-fwd-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.9s both;
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.9s both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.9s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}







