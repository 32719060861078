.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500px;
  @media screen and (min-width: 1600px) {
    padding: 2rem 2rem;
  }
  @media screen and (max-width: 1600px) {
    padding: 2rem 2rem;
  }
  @media screen and (max-width: 1400px) {
    max-width: 100%;
    margin: 0;
    padding: 2rem 4rem;
  }
  @media screen and (max-width: 576px) {
    padding: 30px 15px;
  }

  .navbar-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
      width: fit-content;
    }
  }

  .logo {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--color-additonal);
    margin-right: 2rem;

    @media screen and (max-width: 576px) {
      font-size: 1.3rem;
      margin-right: 10px;
    }

    &.shine {
      background: var(--color-additonal) -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
      background-image: linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
      -webkit-background-size: 200px;
      color: rgba(147, 162, 188, 0);
      background-clip: text;
      animation-name: shine;
      animation-duration: 15s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      text-shadow: 0 0px 0px rgba(255, 255, 255, 0);

      a {
        span {
          &.initial {
            overflow: hidden;
            font-size: 2.3rem;
            font-weight: 800;


          @media screen and (max-width: 576px) {
            font-size: 1.7rem;
          }
        }
          &.first {
            color: var(--color-peach);
          }

          &.last {
            color: var(--color-mint);
          }
        }
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    &.slide-in-top {
      -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }
}

@keyframes shine {
  0% {
    background-position: -500px;
  }
  15% {
    background-position: 500px;
  }
  30% {
    background-position: 500px;
  }
  45% {
    background-position: 500px;
  }
  60% {
    background-position: -500px;
  }
  100% {
    background-position: -500px;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}





