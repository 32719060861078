.menu, .menu-burger-container {

  a {
    display: block;
    position: relative;
    color: var(--color-additonal);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    width: fit-content;
    margin: 0 1rem;
    cursor: pointer;

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 27px;
      left: 50%;
      transform: translate(-50%);
      height: 2px;
      width: 0;
      border-radius: 50%;
      box-shadow: 0 0 5px white;
      background-image: var(--gradient-bar);
      transition: .5s;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }

    &.active {
      background: var(--gradient-text);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 900;

      &:after {
        width: 100%;
      }
    }
  }
}

.navbar-menu .menu-burger {
  color: var(--color-additonal);
  font-size: 2rem;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    font-size: 1.8rem;
  }
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  align-items: center;
  z-index: 3;

  &.slide-in-fwd-center-menu {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
  }

  .menu-burger-box {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: rgba(1, 13, 55, 0.9);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    border: 2px solid rgba(11, 62, 132, 100);
    box-shadow: 0 0 5px rgba(0, 0, 0, 1);
    z-index: 1;

    a {
      display: block;
      margin: 1rem 0;
    }
  }
}