.footer-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    position: relative;
    margin: 15px 0;
    width: 100vw;
    font-size: 12px;
    text-align: center;
    &.slide-in-bottom {
      -webkit-animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .social-container {
      .social {
        font-size: 26px;
        margin: 20px;
        color: var(--color-additonal);
        transition: 0.5s;
        cursor: pointer;
        @media screen and (max-width: 576px) {
          margin: 15px;
        }

        &:hover {
          color: var(--color-mint);
        }
      }
    }

    .copyrights {
      color: var(--color-additonal);
      font-size: 12px;
      line-height: 25px;
      @media screen and (max-width: 576px) {
        line-height: 20px;
        font-size: 10px;
      }
      span {
          display: block;
      }
    }

    .line {
      position: absolute;
      width: 35%;
      height: 2px;
      border-radius: 50%;
      background-image: var(--gradient-bar);
      @media screen and (max-width: 1400px) {
        width: 30%;
      }
      @media screen and (max-width: 1200px) {

        width: 27%;
      }
      @media screen and (max-width: 992px) {

        width: 25%;
      }
      @media screen and (max-width: 768px) {
        width: 20%;
      }
      @media screen and (max-width: 576px) {
        display: none;
      }

      &.left {
        bottom: 45%;
        left: 2%;
        @media screen and (max-width: 1200px) {
          left: 5%;
        }
      }

      &.right {
        bottom: 45%;
        right: 2%;
        background-image: var(--gradient-reverse);
        @media screen and (max-width: 1200px) {
          right: 5%;
        }
      }
    }
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


