.ghost-shadow {
  position: fixed;
  z-index: -3;

  &.mint {
    bottom: 20%;
    right: 10%;
    animation: mint-shadow 30s infinite both linear;
    @media screen and (max-width: 768px) {
      animation: mint-shadow-small 20s infinite both linear;
    }
  }

  &.peach {
    bottom: 5%;
    right: 30%;
    animation: peach-shadow 40s infinite both linear;
    @media screen and (max-width: 768px) {
      animation: peach-shadow-small 30s infinite both linear;
    }
  }
}


@keyframes mint-shadow {
  0% {
    bottom: 20%;
    right: 10%;
    transform: scale(170%);
  }
  20% {
    bottom: 30%;
    right: 20%;
    transform: scale(140%);
  }
  40% {
    bottom: 40%;
    right: 5%;
    transform: scale(80%);
  }
  60% {
    bottom: 50%;
    right: 2%;
    transform: scale(80%);
  }
  80% {
    bottom: 30%;
    right: 5%;
    transform: scale(140%);
  }
  100% {
    bottom: 20%;
    right: 10%;
    transform: scale(170%);
  }
}


@keyframes peach-shadow {
  0% {
    bottom: 5%;
    right: 30%;
    transform: scale(170%);
  }
  20% {
    bottom: 2%;
    right: 10%;
    transform: scale(140%);
  }
  40% {
    bottom: 1%;
    right: 1%;
    transform: scale(80%);
  }
  60% {
    bottom: 24%;
    right: 15%;
    transform: scale(80%);
  }
  80% {
    bottom: 14%;
    right: 25%;
    transform: scale(140%);
  }
  100% {
    bottom: 5%;
    right: 30%;
    transform: scale(170%);
  }
}

@keyframes mint-shadow-small {
  0% {
    bottom: 55%;
    right: -9%;
    transform: scale(170%);
  }
  20% {
    bottom: 50%;
    right: 20%;
    transform: scale(140%);
  }
  40% {
    bottom: 55%;
    right: 5%;
    transform: scale(80%);
  }
  60% {
    bottom: 60%;
    right: -10%;
    transform: scale(80%);
  }
  80% {
    bottom: 45%;
    right: -20%;
    transform: scale(140%);
  }
  100% {
    bottom: 55%;
    right: -9%;
    transform: scale(170%);
  }
}


@keyframes peach-shadow-small {
  0% {
    bottom: -15%;
    right: 3%;
    transform: scale(170%);
  }
  20% {
    bottom: 2%;
    right: 10%;
    transform: scale(140%);
  }
  40% {
    bottom: -1%;
    right: 1%;
    transform: scale(80%);
  }
  60% {
    bottom: 1%;
    right: -35%;
    transform: scale(80%);
  }
  80% {
    bottom: -5%;
    right: -45%;
    transform: scale(140%);
  }
  100% {
    bottom: -15%;
    right: 3%;
    transform: scale(170%);
  }
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 114px);
  padding-bottom: 150px;
}

.hero-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 0 4rem;
  @media screen and (min-width: 1600px) {
    width: 70%;
  }
  @media screen and (max-width: 1600px) {
    max-width: 1320px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 100%;
  }
  @media screen and (max-width: 992px) {
    padding: 0 2rem;
  }

  &.fade-in-fwd {
    -webkit-animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
    animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
  }

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-additonal);
    font-size: clamp(1rem, 0.7742rem + 1.2903vw, 2rem);
    letter-spacing: 1.5px;
    line-height: 70px;

    @media screen and (max-width: 1400px) {
      line-height: 50px;
    }

    @media screen and (max-width: 1200px) {
      line-height: 45px;
    }

    @media screen and (max-width: 576px) {
      line-height: 35px;
    }

    @media screen and (orientation: landscape) {
      margin: 2rem 0;
    }

    .typewriter-wrapper {
      height: 65px;
      width: fit-content;
      animation: cursor 1s linear infinite both;
      @media screen and (max-width: 1400px) {
        height: 55px;
      }
      @media screen and (max-width: 992px) {
        height: 50px;
      }
      @media screen and (max-width: 576px) {
        height: 40px;
      }
    }
  }
}

@keyframes cursor {
  from {
    border-right: 3px solid transparent;
  }
  50% {
    border-right: 3px solid var(--color-additonal);
  }
  to {
    border-right: 3px solid transparent;
  }
}

.imp {
  font-family: var(--font-family);
  font-size: clamp(1.25rem, 0.7419rem + 2.9032vw, 2.5rem);
  font-weight: 800;
  letter-spacing: 8px;
  line-height: 65px;
  width: fit-content;

  @media screen and (max-width: 1400px) {
    line-height: 55px;
  }
  @media screen and (max-width: 992px) {
    line-height: 50px;
    letter-spacing: 5px;
  }
  @media screen and (max-width: 576px) {
    line-height: 40px;
  }
}

.wrap {
  white-space: nowrap;
  animation: cursor linear 1s infinite;
}

.button {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1200px) {
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (orientation: landscape) {
    justify-content: flex-end;
  }

  .btn-a, .btn-b {
    padding: 0.7em 1.4em;
    border: none;
    outline: none;
    color: var(--color-additonal);
    font-family: inherit;
    font-weight: 500;
    font-size: clamp(0.1rem, 0.7rem + 1.2903vw, 1.3rem);
    margin: 70px 30px 0 20px;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 12px;

    @media screen and (max-width: 576px) {
      margin: 50px 10px 0 0;
    }

    span {
      display: block;
      padding-top: 10px;
    }
  }
}

.btn-a:after, .btn-b:after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #052055;
  opacity: 0.9;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.btn-a:before {
  content: "";
  background: linear-gradient(
                  45deg,
                  var(--color-peach), var(--color-mint),
                  var(--color-peach), var(--color-mint)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 40s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 1;
}

.btn-b:before {
  content: "";
    background: linear-gradient(
                    45deg,
                    var(--color-mint), var(--color-peach),
                    var(--color-mint), var(--color-peach)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 40s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 1;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.btn-a:hover:after, .btn-b:hover:after {
  background: transparent;
}

.btn-a:hover, .btn-b:hover {
  color: #27292d;
  font-weight: 800;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.scale-up-center-ghost {
  -webkit-animation: scale-up-center 8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
