.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(2, 17, 62, 0.9);
  overscroll-behavior: contain;
  overflow-y: auto;
  z-index: 9;

  .modal-wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    @media screen and (max-width: 1200px) {
      padding: 2rem 4rem;
    }
    @media screen and (max-width: 576px) {
      padding: 1rem 20px;
    }

    .modal {
      position: relative;
      background-color: #0a3a7e;
      display: flex;
      flex-direction: column;
      width: 80%;
      padding: 3rem;
      border-radius: 0.5em;
      box-shadow: 0 0 5px 1px rgb(0, 0, 0);
      z-index: 10;

      @media screen and (max-width: 1600px) {
        width: 90%;
      }
      @media screen and (max-width: 1400px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        padding: 1rem 15px;
      }


      .exit {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
        width: fit-content;
        padding: 15px 25px;
        border: none;
        background-color: transparent;
        color: var(--color-peach);
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}

h2 {
  width: fit-content;
  padding: 3rem 0 0 16px;
  font-size: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.1rem;
    line-height: 25px;
  }
}

.modal-row {
  border-bottom: 1px solid var(--color-additonal);
  padding: 3rem 1rem;

  @media screen and (max-width: 992px) {
    padding: 2rem 1rem;
  }

  &:last-child {
    border-bottom: none;
  }


  h3 {
    padding-bottom: 2rem;
    @media screen and (max-width: 576px) {
      padding-bottom: 1.5rem;
    }
  }
}


.summary {
  display: flex;
  column-gap: 40px;
  padding-bottom: 5rem;
  @media screen and (max-width: 992px) {
    padding-top: 0;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .image {
    text-align: center;
    margin: auto 0;
    @media screen and (max-width: 992px) {
      margin: 0 auto;
      padding: 20px 0;
    }

    img {
      max-height: 250px;
      filter: drop-shadow(-13px 15px 5px #0b1d4d);
      @media screen and (max-width: 576px) {
      max-height: 150px;
    }
    }
  }
}

.modal-tech {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  text-align: center;
  @media screen and (max-width: 576px) {
    row-gap: 30px;
    justify-content: center;
    margin: 0 auto;
  }

  .tech-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .modal-tech-icon {
      height: 100px;
      padding-bottom: 15px;
      @media screen and (max-width: 992px) {
        height: 80px;
      }
      @media screen and (max-width: 576px) {
        padding-bottom: 10px;
        height: 70px;
      }
    }
  }
}

.scale-in-hor-center {
  -webkit-animation: scale-in-hor-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-hor-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

.fade-in-modal-content {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button2 {
  position: relative;
  padding: 10px 20px;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  color: var(--color-mint);
  text-decoration: none;
  overflow: hidden;
  font-family: var(--font-family);
  border: 2px solid var(--color-additonal);
  transition: all 0.1s linear;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    padding: 5px 10px;
  }

  &:hover {
    border: 2px solid transparent;

    span {
      position: absolute;
      display: block;

      &:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, transparent, var(--color-peach));
        animation: animate1 1.5s linear infinite;
      }

      @keyframes animate1 {
        0% {
          left: -100%;
        }
        50%,
        100% {
          left: 100%;
        }
      }

      &:nth-child(2) {
        top: -100%;
        right: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(180deg, transparent, var(--color-mint));
        animation: animate2 1.5s linear infinite;
        animation-delay: 0.5s;
      }

      @keyframes animate2 {
        0% {
          top: -100%;
        }
        50%,
        100% {
          top: 100%;
        }
      }

      &:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 100%;
        background: linear-gradient(270deg, transparent, var(--color-mint));
        animation: animate3 1.5s linear infinite;
        animation-delay: 0.75s;
      }

      @keyframes animate3 {
        0% {
          right: -100%;
          height: 3px;
        }
        50%,
        100% {
          height: 2px;
          right: 100%;
        }
      }

      &:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(360deg, transparent, var(--color-peach));
        animation: animate4 1.5s linear infinite;
        animation-delay: 1s;
      }

      @keyframes animate4 {
        0% {
          bottom: -100%;
        }
        50%,
        100% {
          bottom: 100%;
        }
      }
    }
  }
}

.other-tools ul {
  padding-left: 50px;
  @media screen and (max-width: 768px) {
    padding-left: 20px;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  .screenshot {
    display: flex;
    flex-basis: 48%;

    @media screen and (max-width: 992px) {
      flex-basis: 100%;
      padding: 0;
    }

    img {
      max-width: 100%;
      box-shadow: #031949 0 0 5px;
      border-radius: 10px;
    }
  }
}

