.row {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  width: 100%;
  height: fit-content;
  @media screen and (max-width: 1200px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
  }
  @media screen and (max-width: 992px) {
    column-gap: 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 13px;
  }

  .col-left {
    flex: 1 1 50%;
    justify-content: flex-start;
    padding-right: 25px;
    @media screen and (max-width: 1200px) {
      padding: 0 0 50px 0;
      justify-content: center;
      width: 100%;
    }

    .email-contact {
      padding-top: 40px;
      line-height: 30px;
    }

    .email-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bolder;
      width: fit-content;
      cursor: pointer;
      @media screen and (max-width: 576px) {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: auto;
      }

      .fa-at {
        font-size: 40px;
        padding: 10px;
        @media screen and (max-width: 576px) {
          font-size: 25px;
        }
      }


      a {
        display: flex;
        align-items: center;
        @media screen and (max-width: 576px) {
          display: block;
          margin: 0 auto;
        }


        .email {
          @media screen and (max-width: 576px) {
            display: block;
            line-height: 20px;
          }
        }
      }

      &:hover {
        background: var(--gradient-text);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 50%;
    padding-left: 25px;
    @media screen and (max-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
      width: 100%;
      flex-basis: 100%;
    }
  }
}

.social-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-bottom: 160px;
  color: var(--color-additonal);
  font-size: 18px;

  @media screen and (max-width: 576px) {
    font-size: 14px;
    padding-bottom: 140px;
  }


  .social-section {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    &.bounce-in-top {
      -webkit-animation: bounce-in-top 1.6s 1.2s both;
      animation: bounce-in-top 1.6s 1.2s both;
    }

    .social-contact {
      text-align: center;
      padding-bottom: 15px;
    }

    .arrows {
      position: absolute;
      font-size: 1.5rem;
      animation: arrow 3s infinite linear;

      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }
  }
}


@keyframes arrow {
  0% {
    bottom: -25px;
  }
  10% {
    bottom: -30px;
  }
  20% {
    bottom: -25px;
  }
  30% {
    bottom: -25px
  }
  40% {
    bottom: -30px;
  }
  50% {
    bottom: -25px;
  }
  100% {
    bottom: -25px;
  }
}


@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translate(0, -500px);
    transform: translate(0, -500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translate(0, -65px);
    transform: translate(0, -65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translate(0, -28px);
    transform: translate(0, -28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translate(0, -8px);
    transform: translate(0, -8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-top {
  0% {
    -webkit-transform: translate(0, -500px);
    transform: translate(0, -500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translate(0, -65px);
    transform: translate(0, -65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translate(0, -28px);
    transform: translate(0, -28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translate(0, -8px);
    transform: translate(0, -8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}




