img.error {
  width: 100%;
  opacity: 0.7;
  @media screen and (max-width: 1200px) {
    padding-top: 50px;
    width: 70%;
  }
  @media screen and (max-width: 992px) {
    padding-top: 70px;
    width: 100%;
  }
}
