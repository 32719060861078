.card-wrapper {
  display: flex;
  justify-content: space-between;
  flex-basis: 30%;
  margin: 0 auto 90px auto;
  @media screen and (max-width: 1400px) {
    flex-basis: 40%;
  }
  @media screen and (max-width: 768px) {
    flex-basis: 80%;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  background-color: #0a3a7e;
  border-radius: 15px;
  box-shadow: -3px 3px 5px #0b1d4d;
  transition: 0.3s;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    height: 190px;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    height: 250px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: 170px;
  }
  @media screen and (max-width: 576px) {
    flex-grow: 0;
    height: 150px;
  }

  &:hover {
    transform: scale(115%);
    box-shadow: -5px 5px 5px #0b1d4d;
  }

  .column-left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px 10px 30px;
    @media screen and (max-width: 992px) {
      padding: 0 0 20px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 0 0 20px;
    }
    @media screen and (max-width: 576px) {
      padding: 0;

    }

    img {
      height: 150px;
      transition: 0.9s;
      filter: drop-shadow(-3px 2px 7px #0b1d4d);

      @media screen and (max-width: 992px) {
        padding: 10px 0 10px;
        height: 150px;
      }
      @media screen and (max-width: 576px) {
        height: 100px;
        padding: 0 0 0 20px;
      }
    }
  }

  .column-right {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-content: center;
    line-height: 30px;
    padding-right: 20px;
    text-align: center;
    @media screen and (max-width: 992px) {
      padding: 0 20px 10px;
      @media screen and (max-width: 576px) {
        padding: 20px 10px 20px 0;
      }
    }
  }
}

.card:hover img {
  transform: scale(110%);
  transform-origin: bottom;
  filter: drop-shadow(-13px 15px 5px #0b1d4d);
}
